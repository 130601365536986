const images = {
  data: [
    {
      filename: "imagem42.jpg",
      name: "Páscoa 2006",
      slug: "pascoa-2006",
      date: 2010,
      material: "acrílica sobre madeira",
      width: 176,
      height: 118,
      position: 2,
      visible: true,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-gut.jpg",
      name: "Gut",
      slug: "gut",
      date: 2015,
      material: "óleo sobre tela",
      width: 180,
      height: 137,
      position: 1,
      visible: true,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem54.jpg",
      name: "Felipe",
      slug: "felipe",
      date: 2013,
      material: "óleo sobre tela",
      width: 76,
      height: 100,
      position: 3,
      visible: true,
      isAvailable: true,
      price: "12000",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-flora-2014.jpg",
      name: "Flora",
      slug: "flora",
      date: 2014,
      material: "óleo sobre tela",
      width: 128,
      height: 188,
      position: 2,
      visible: true,
      isAvailable: true,
      price: "15000",
      wasSold: false
    },
    {
      filename: "imagem24.jpg",
      name: "David Bowie",
      slug: "david-bowie",
      date: 2007,
      material: "óleo sobre tela",
      width: 90,
      height: 95,
      position: 12,
      visible: true,
      isAvailable: false,
      price: "4400",
      wasSold: true
    },
    {
      filename: "imagem14.jpg",
      name: "Vez",
      slug: "vez",
      date: 2008,
      material: "óleo sobre tela",
      width: 94,
      height: 94,
      position: 10,
      visible: true,
      isAvailable: true,
      price: "5000",
      wasSold: false
    },
    {
      filename: "imagem9.jpg",
      name: "Amea",
      slug: "amea",
      date: 2009,
      material: "óleo sobre tela",
      width: 196,
      height: 137,
      position: 9,
      visible: true,
      isAvailable: true,
      price: "8000",
      wasSold: false
    },
    {
      filename: "imagem49.png",
      name: "Vernáculo",
      slug: "vernaculo",
      date: 2011 - 12,
      material: "óleo sobre tela",
      width: 133,
      height: 133,
      position: 5,
      visible: true,
      isAvailable: true,
      price: "10000",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-sao-miguel.jpg",
      name: "São Miguel dos Milagres",
      slug: "sao-miguel-dos-milagres",
      date: 2017,
      material: "óleo sobre tela",
      width: 63,
      height: 90,
      position: 0,
      visible: true,
      isAvailable: true,
      price: "40000",
      wasSold: false
    },
    {
      filename: "imagem45.png",
      name: "Isa",
      slug: "isa",
      date: 2010,
      material: "óleo sobre tela",
      width: 142,
      height: 180,
      position: 7,
      visible: true,
      isAvailable: true,
      price: "12000",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-papa-n-trippy.jpg",
      name: "Papá Não-Trippy (e desenho de Julia Debasse)",
      slug: "papa-nao-trippy",
      date: 2011,
      material: "óleo sobre tela",
      width: 80,
      height: 80,
      position: 6,
      visible: true,
      isAvailable: true,
      price: "6000",
      wasSold: false
    },
    {
      filename: "imagem51.png",
      name: "Eugenia",
      slug: "eugenia",
      date: 2012 - 13,
      material: "óleo sobre tela",
      width: 165,
      height: 124,
      position: 4,
      visible: true,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem47.jpg",
      name: "Mapa-Mundi",
      slug: "mapa-mundi",
      date: 2010,
      material: "óleo sobre tela",
      width: 110,
      height: 65,
      position: 8,
      visible: true,
      isAvailable: false,
      price: "Presente",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-esta.jpg",
      name: "Esta",
      slug: "esta",
      date: 2025,
      material: "óleo sobre tela",
      width: 100,
      height: 140,
      position: 40,
      visible: true,
      isAvailable: true,
      price: "5000",
      wasSold: false
    },
    {
      filename: "imagem48.jpg",
      name: "Milagre",
      slug: "milagre",
      date: 2008,
      material: "acrílica sobre madeira",
      width: 140,
      height: 185,
      position: 38,
      visible: true,
      isAvailable: true,
      price: "4000",
      wasSold: false
    },
    {
      filename: "imagem39.jpg",
      name: "Futuro",
      slug: "futuro",
      date: 2006,
      material: "acrílica sobre madeira",
      width: 50,
      height: 80,
      position: 40,
      visible: true,
      isAvailable: true,
      price: "3000",
      wasSold: true
    },
    {
      filename: "imagem41.jpg",
      name: "Ekkyklema",
      slug: "ekkyklema",
      date: 2010,
      material: "acrílica sobre canvas",
      width: 236,
      height: 196,
      position: 39,
      visible: true,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem15.jpg",
      name: "Bocetas Ortogonais",
      slug: "bocetas-ortogonais",
      date: 2008,
      material: "acrílica sobre canvas",
      width: 196,
      height: 236,
      position: 20,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem16.jpg",
      name: "Os Antigos Acreditavam Que",
      slug: "os-antigos-acreditavam-que",
      date: 2008,
      material: "óleo sobre tela",
      width: 142,
      height: 186,
      position: 11,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem17.jpg",
      name: "Arqueo",
      slug: "arqueo",
      date: 2008,
      material: "acrílica sobre madeira",
      width: 185,
      height: 140,
      position: 21,
      visible: false,
      isAvailable: false,
      price: "Presente",
      wasSold: false
    },
    {
      filename: "imagem18.jpg",
      name: "Merlin",
      slug: "merlin",
      date: 2008,
      material: "acrílica sobre papel",
      width: 46,
      height: 66,
      position: 22,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem20.jpg",
      name: "Polaróides",
      slug: "polaroides",
      date: 2007,
      material: "sharpie e óleo sobre papel",
      width: 20,
      height: 29,
      position: 18,
      visible: false,
      isAvailable: false,
      price: "600",
      wasSold: true
    },
    {
      filename: "imagem21.jpg",
      name: "18 anos",
      slug: "18-anos",
      date: 2008,
      material: "bastões de óleo sobre papel",
      width: 46,
      height: 66,
      position: 23,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem22.jpg",
      name: "Niminiminimi",
      slug: "niminiminimi",
      date: 2007,
      material: "lápis de cor sobre papel",
      width: 29,
      height: 42,
      position: 24,
      visible: false,
      isAvailable: true,
      price: "2000",
      wasSold: false
    },
    {
      filename: "imagem23.jpg",
      name: "Convite para uma Festa",
      slug: "convite-para-uma-festa",
      date: 2007,
      material: "acrílica sobre papel",
      width: 46,
      height: 66,
      position: 14,
      visible: false,
      isAvailable: true,
      price: "3000<br>Com caixa de vidro.",
      wasSold: false
    },
    {
      filename: "imagem26.jpg",
      name: "Urbanos",
      slug: "urbanos",
      date: 2007,
      material: "acrílica sobre canvas",
      width: 276,
      height: 178,
      position: 15,
      visible: false,
      isAvailable: false,
      price: "500",
      wasSold: true
    },
    {
      filename: "imagem27.jpg",
      name: "Aqui Eu Vi uma Mulher Cantando",
      slug: "aqui-eu-vi-uma-mulher-cantando",
      date: 2007,
      material: "óleo sobre tela",
      width: 100,
      height: 80,
      position: 27,
      visible: false,
      isAvailable: false,
      price: "1200",
      wasSold: true
    },
    {
      filename: "imagem28.jpg",
      name: "Alívio/Guenza",
      slug: "alivio-guenza",
      date: 2006,
      material: "acrílica sobre papel",
      width: 21,
      height: 29,
      position: 26,
      visible: false,
      isAvailable: false,
      price: "Presente",
      wasSold: false
    },
    {
      filename: "imagem31.jpg",
      name: "Com Rodrigo Eberienos",
      slug: "com-rodrigo-eberienos",
      date: 2006,
      material: "acrílica sobre madeira",
      width: 40,
      height: 60,
      position: 13,
      visible: false,
      isAvailable: false,
      price: "Presente",
      wasSold: false
    },
    {
      filename: "imagem32.jpg",
      name: "Arquétipos: 1: Jesus",
      slug: "arquetipos-1-jesus",
      date: 2007,
      material: "acrílica sobre canvas",
      width: 30,
      height: 40,
      position: 28,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem35.jpg",
      name: "Mulher Morta",
      slug: "mulher-morta",
      date: 2007,
      material: "acrílica sobre canvas",
      width: 18,
      height: 25,
      position: 29,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem38.jpg",
      name: "Mike Tyson",
      slug: "mike-tyson",
      date: 2006,
      material: "pastel sobre papel",
      width: 42,
      height: 29,
      position: 31,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem44.png",
      name:
        "Paul McCartney cumprimentando Brian Wilson no camarim após a estréia de Smile",
      slug: "mccartney",
      date: 2010,
      material: "acrílica sobre papel",
      width: 25,
      height: 25,
      position: 17,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-coracao.jpg",
      name: "Coração",
      slug: "coracao",
      date: 2014,
      material: "óleo sobre tela",
      width: 60,
      height: 40,
      position: 35,
      visible: true,
      isAvailable: true,
      price: "7000",
      wasSold: false
    },
    {
      filename: "caca-e-coleta-gustavo-saiani.jpg",
      name: "Caça e Coleta",
      slug: "caca-e-coleta",
      date: 2014,
      material: "óleo sobre tela",
      width: 60,
      height: 40,
      position: 36,
      visible: true,
      isAvailable: true,
      price: "7000",
      wasSold: false
    },
    {
      filename: "imagem52.jpg",
      name: "Egito",
      slug: "egito",
      date: 2013,
      material: "óleo sobre tela",
      width: 164,
      height: 173,
      position: 37,
      visible: true,
      isAvailable: true,
      price: "8000",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-bananalidade.png",
      name: "Bananalidade",
      slug: "bananalidade",
      date: 2016,
      material: "óleo sobre tela",
      width: 160,
      height: 100,
      position: 34,
      visible: true,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem50.jpg",
      name: "Doutrina",
      slug: "doutrina",
      date: 2011 - 12,
      material: "óleo sobre tela",
      width: 51,
      height: 35,
      position: 41,
      visible: true,
      isAvailable: true,
      price: "3000",
      wasSold: false
    },
    {
      filename: "imagem53.png",
      name: "Não",
      slug: "nao",
      date: 2013,
      material: "óleo sobre tela",
      width: 66,
      height: 39,
      position: 42,
      visible: true,
      isAvailable: true,
      price: "7000",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-gondwana.jpg",
      name: "Gondwana",
      slug: "gondwana",
      date: 2014,
      material: "óleo sobre tela",
      width: 30,
      height: 28,
      position: 43,
      visible: true,
      isAvailable: true,
      price: "3000",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-laniakea.jpg",
      name: "Laniakea",
      slug: "laniakea",
      date: 2014,
      material: "óleo sobre tela",
      width: 30,
      height: 28,
      position: 44,
      visible: true,
      isAvailable: true,
      price: "3000",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-cassi.png",
      name: "Cassi",
      slug: "cassi",
      date: 2016,
      material: "óleo sobre tela",
      width: 160,
      height: 100,
      position: 33,
      visible: true,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "gustavo-saiani-parlamento-islandes.jpg",
      name: "Parlamento Islandês",
      slug: "parlamento-islandes",
      date: 2015,
      material: "óleo sobre tela",
      width: 62,
      height: 32,
      position: 45,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem37.jpg",
      name: "Menino Cego com Dois Sonhos",
      slug: "menino-cego-com-dois-sonhos",
      date: 2007,
      material: "acrílica sobre canvas",
      width: 18,
      height: 25,
      position: 30,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem12.jpg",
      name: "Valsa Binária",
      slug: "valsa-binaria",
      date: 2009,
      material: "acrílica sobre madeira",
      width: 54,
      height: 65,
      position: 19,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    },
    {
      filename: "imagem13.jpg",
      name: "Convite para uma Festa #2",
      slug: "convite-para-uma-festa-2",
      date: 2008,
      material: "acrílica sobre papel",
      width: 80,
      height: 66,
      position: 16,
      visible: false,
      isAvailable: true,
      price: "3000<br>Com caixa de vidro.",
      wasSold: false
    },
    {
      filename: "imagem8.jpg",
      name: ", creio,",
      slug: "creio",
      date: 2006,
      material: "acrílica sobre canvas",
      width: 87,
      height: 54,
      position: 25,
      visible: false,
      isAvailable: false,
      price: "Presente",
      wasSold: false
    },
    {
      filename: "imagem40.jpg",
      name: "Quarto de TV do meu Pai",
      slug: "quarto-de-tv-do-meu-pai",
      date: 2005,
      material: "caneta permanente sobre papel",
      width: 29,
      height: 21,
      position: 32,
      visible: false,
      isAvailable: false,
      price: "",
      wasSold: false
    }
  ]
};

export default images;
